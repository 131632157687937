/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-16 14:34:54
 */

// import { parseTime } from '@/plugins/lib/modules/date'
import { status, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 60 },

  {
    label: '部门名称',
    kname: 'name' },

  {
    label: '部门主任',
    kname: 'directorName' },

  {
    label: '部门分管领导',
    kname: 'headsName' },

  {
    label: '部门绩效专责',
    kname: 'perSpecialistName' },

  {
    label: '是否管理部门',
    kname: 'isManage',
    filterFn: function filterFn(v) {
      return ['是', '否'][v];
    } },

  {
    label: '创建时间',
    kname: 'createTime',
    width: 140 },

  {
    label: '状态',
    kname: 'status',
    component: {
      name: 'c-switch',
      events: {
        change: ctx.changeStatus },

      props: {
        disabled: function disabled(v) {
          return v.isChange === 1 || !ctx.hasPermission([64]);
        } } } },



  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};



export var searchOption = [
{
  kname: 'name',
  label: '部门名称：',
  component: {
    name: 'el-input',
    props: {
      clearable: true,
      placeholder: '请输入' } } },



{
  kname: 'status',
  label: '状态：',
  component: {
    name: 'selector',
    props: {
      list: status,
      clearable: true } } }];